import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Image from "next/image";
import { ReactNode, useEffect, VFC } from "react";
import { useAnalyticsContext } from "../context/AnalyticsContext";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    errorText: {
      margin: theme.spacing(4, "auto", 0),
    },
    heading: {
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(),
    },
    content: {
      maxWidth: 720,
      padding: theme.spacing(0, 2),
      position: "relative",
      textAlign: "center",
      width: "100%",
    },
    goBtn: {
      marginTop: theme.spacing(3),
    },
  }),
  { classNamePrefix: "Status" }
);

type StatusProps = {
  className?: string;
  code: number;
  heading?: string;
  message?: ReactNode;
  hideGoHome?: boolean;
} & (
  | {
      captureStatus?: false;
    }
  | {
      captureStatus: true;
      associatedError?: Error;
    }
);

export const Status: VFC<StatusProps> = ({
  code,
  heading = "Oops, something went wrong.",
  message = "This page doesn't exist. Try going back to our homepage.",
  hideGoHome,
  className,
  captureStatus,
  associatedError,
}: StatusProps & { associatedError?: Error }) => {
  const classes = useStyles();

  const {
    state: { sentry },
  } = useAnalyticsContext();

  useEffect(() => {
    if (captureStatus)
      sentry?.captureException(associatedError !== undefined ? associatedError : "Status component captured a status", {
        tags: {
          isStatusCoded: true,
          statusCode: code,
        },
      });
  }, [associatedError, captureStatus, code, sentry]);

  return (
    <div className={clsx(classes.root, className)}>
      <main className={classes.content}>
        {(() => {
          switch (code) {
            case 404:
              return <Image src="/img/404.svg" layout="responsive" width="480" height="173" />;
            case 5000:
              return <Image src="/img/5000.svg" layout="responsive" width="595" height="173" />;
          }
        })()}

        <Box className={classes.errorText}>
          <Typography variant="h1" className={classes.heading}>
            {heading}
          </Typography>
          <Typography>{message}</Typography>
          {!hideGoHome && (
            <Button className={classes.goBtn} color="primary" variant="contained" href="/">
              Go back home
            </Button>
          )}
        </Box>
      </main>
    </div>
  );
};

export default Status;
