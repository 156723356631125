import { add, differenceInMilliseconds, parse, startOfDay } from "date-fns";
import { DayOfWeek } from "../reclaim-api/Calendars";
import { HHMMSS } from "../reclaim-api/types";
import { LocalTimeInterval, TimePolicy } from "../reclaim-api/Users";
import { getDayOfWeekFromDate, MILLISECONDS_PER_SECOND, msInHHMMSS } from "./dates";
import { typedKeys } from "./objects";

export const countMsInLocalTimeInterval = (interval: LocalTimeInterval) => {
  if (typeof interval.duration === "number") return interval.duration * MILLISECONDS_PER_SECOND;

  const startMs = msInHHMMSS(interval.start as HHMMSS);
  const endMs = msInHHMMSS(interval.end as HHMMSS);

  return endMs - startMs;
};

export const countMsInTimePolicy = (timePolicy: TimePolicy): number =>
  Object.values(timePolicy.dayHours).reduce(
    (totalMs, dayHours) =>
      totalMs + dayHours.intervals.reduce((dayMs, interval) => dayMs + countMsInLocalTimeInterval(interval), 0),
    0
  );

const testIntervalsForDateOverlap = (intervals: LocalTimeInterval[], date: Date): boolean => {
  const relativeDate = startOfDay(date);

  return !!intervals.find((interval) => {
    const start = parse(interval.start, "HH:mm:ss", relativeDate);
    const end = parse(interval.end, "HH:mm:ss", relativeDate);

    return start.getTime() <= date.getTime() && end.getTime() >= date.getTime();
  });
};

export const timeOfDayOverlapsTimePolicy = (policy: TimePolicy, time: HHMMSS): boolean => {
  const timeAsDate = parse(time, "HH:mm:ss", new Date());

  return !!Object.keys(policy.dayHours).find((day: DayOfWeek) => {
    const intervals = policy.dayHours[day]?.intervals;
    return !!intervals && testIntervalsForDateOverlap(intervals, timeAsDate);
  });
};

export const dateOverlapsTimePolicy = (policy: TimePolicy, date: Date): boolean => {
  const day = getDayOfWeekFromDate(date);
  const intervals = policy.dayHours[day]?.intervals;

  return !!intervals && testIntervalsForDateOverlap(intervals, date);
};

export const scanTimePolicyForNearestEndOfDay = (
  policy: TimePolicy,
  date: Date,
  scanForward: boolean = true
): Date | undefined => {
  let endOfDay: Date | undefined;
  let dayScanLimit = 0;
  let scanDate = date;
  let scanDay = getDayOfWeekFromDate(scanDate);

  while (!endOfDay && dayScanLimit <= 6) {
    const endCheck = policy.dayHours[scanDay]?.endOfDay;
    const endDate = !!endCheck ? parse(endCheck, "HH:mm:ss", scanDate) : undefined;
    const reverseCheck = scanForward || (endDate && endDate?.getTime() < date.getTime());
    if (endCheck && endDate && reverseCheck) {
      const [hours] = endCheck.split(":");
      scanDate.setHours(+hours);
      endOfDay = scanDate;
    } else {
      scanDate = startOfDay(add(scanDate, { days: scanForward ? 1 : -1 }));
      scanDay = getDayOfWeekFromDate(scanDate);
      dayScanLimit++;
    }
  }

  return endOfDay;
};

export const getTimePolicyStartOfDayforDate = (policy: TimePolicy, date: Date): Date | undefined => {
  const day = getDayOfWeekFromDate(date);
  const hourOfDay = policy.dayHours[day]?.intervals?.[0]?.start;

  return !!hourOfDay ? parse(hourOfDay, "HH:mm:ss", startOfDay(date)) : undefined;
};

export const getTimePolicyEndOfDayforDate = (policy: TimePolicy, date: Date): Date | undefined => {
  const day = getDayOfWeekFromDate(date);
  const hourOfDay = policy.dayHours[day]?.endOfDay;

  return !!hourOfDay ? parse(hourOfDay, "HH:mm:ss", startOfDay(date)) : undefined;
};

export const timePolicyHasHomogenousHours = (policy: TimePolicy): boolean => {
  let isHomogeneous = true;
  let testInterval: LocalTimeInterval | undefined;

  typedKeys(policy.dayHours).find((day) => {
    policy.dayHours[day]?.intervals.find((interval) => {
      if (!testInterval) {
        testInterval = interval;
      }

      if (testInterval.start !== interval.start || testInterval.end !== interval.end) {
        isHomogeneous = false;
      }
      return !isHomogeneous;
    });
    return !isHomogeneous;
  });

  return isHomogeneous;
};

export const dayCountOfDurationOverlapInTimePolicy = (policy: TimePolicy, durationMs: number): number => {
  const relativeDate = new Date();
  let count = 0;

  typedKeys(policy.dayHours).forEach((day) => {
    const intervalFit = policy.dayHours[day]?.intervals.find((interval) => {
      const start = parse(interval.start, "HH:mm:ss", relativeDate);
      const end = parse(interval.end, "HH:mm:ss", relativeDate);

      return differenceInMilliseconds(end, start) > durationMs;
    });

    if (intervalFit) count++
  });

  return count;
}