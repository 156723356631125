import { atom, useAtom, useSetAtom } from "jotai";
import { useEffect, useRef } from "react";
import { useUserContext } from "../../context/UserContext";
import { reclaim } from "../../reclaim-api";
import {
  InviteSource,
  MembershipRole,
  PartialTeam,
  PartialTeamInvitation,
  PartialTeamMember,
  Team,
  TeamInvitation,
  TeamMember,
} from "../../reclaim-api/team/Team";
import { User } from "../../reclaim-api/Users";
import { useCallbackSafeRef } from "../useCallbackSafeRef";
import { useSubscriptionWithCallback } from "../useSubscription";

export type UseTeamState = Readonly<{ team: Team; userMembership: TeamMember; userIsSoleAdmin: boolean }>;
export type UseTeamStateUndef = { [PROP in keyof UseTeamState]: UseTeamState[PROP] | undefined };

const DEFAULT_STATE: UseTeamStateUndef = {
  team: undefined,
  userMembership: undefined,
  userIsSoleAdmin: undefined,
};

const stateAtom = atom<UseTeamState | UseTeamStateUndef>(DEFAULT_STATE);
// keep the initial state in an atom so the
// normal state isn't defined without it when
// navigating.  Don't want to read normal state
// in the initializer since it would trigger
// updates at root
const initialStateAtom = atom<Team | undefined>(undefined);

const teamToUseTeamState = (team: Team, user: User): UseTeamState => {
  const userMembership = team.members.find(({ teamMemberId: { userId } }) => userId === user.id);
  if (!userMembership) throw new Error(`Could not find team member for user (${user.id}) on team (${team.id})`);

  return Object.freeze({
    team,
    userMembership,
    userIsSoleAdmin: team?.adminCount === 1 && userMembership?.membershipRole === MembershipRole.Admin,
  });
};

export type UseTeamReturnType<ALLOW_UNDEF extends boolean> = ALLOW_UNDEF extends true
  ? UseTeamStateUndef
  : UseTeamState;

export const useTeamInitializer = (): Team | undefined => {
  const setState = useSetAtom(stateAtom);
  const [initialTeam, setInitialTeam] = useAtom(initialStateAtom);
  const [{ user }] = useUserContext();
  const getStartedRef = useRef(false);

  const isReady = !!user && !!initialTeam;

  useEffect(() => {
    if (!user || getStartedRef.current || initialTeam) return;
    getStartedRef.current = true;

    void reclaim.team.get().then((team) => {
      setState(teamToUseTeamState(team, user));
      setInitialTeam(team);
    });
  }, [initialTeam, setInitialTeam, setState, user]);

  useSubscriptionWithCallback(
    reclaim.team.watchTeam$$,
    // because `user` is part of the enabled clause,
    // we can gaurentee it's always defined here
    (team) => setState(teamToUseTeamState(team, user!)),
    isReady
  );

  return initialTeam;
};

export const useTeamState = <ALLOW_UNDEF extends boolean = false>(
  allowUndefined?: ALLOW_UNDEF
): UseTeamReturnType<ALLOW_UNDEF> => {
  const [state] = useAtom(stateAtom);

  if (!allowUndefined && (!state.team || !state.userMembership))
    throw new Error("useTeamInitializer must return a Team object before useTeam can be called");

  return state as UseTeamReturnType<ALLOW_UNDEF>;
};

export type UseTeamActionsReturnType = {
  invite: (invites: PartialTeamInvitation[], source?: InviteSource) => Promise<TeamInvitation[]>;
  uninvite: (id: string) => Promise<void>;
  removeMember: (userId: string) => Promise<void>;
  patchMember: (userId: string, member: PartialTeamMember) => Promise<void>;
  patchTeam: (team: PartialTeam) => Promise<void>;
  leaveTeam: () => Promise<void>;
};

export const useTeamActions = (): UseTeamActionsReturnType => {
  const [{ user }, userActions] = useUserContext();

  return {
    invite: useCallbackSafeRef(async (invites, source) => {
      const invitations = await reclaim.team.invite(invites, source);
      return invitations;
    }),
    uninvite: useCallbackSafeRef(async (id) => {
      try {
        await reclaim.team.deleteInvitation(id);
      } catch (err) {
        throw new Error("Could not revoke team invitation.", { cause: err });
      }
    }),
    removeMember: useCallbackSafeRef(async (userId) => {
      try {
        await reclaim.team.deleteMember(userId);
      } catch (err) {
        throw new Error("Could not remove user from team.", { cause: err });
      }
    }),
    patchMember: useCallbackSafeRef(async (userId, member) => {
      try {
        await reclaim.team.patchMember(userId, member);
      } catch (err) {
        throw new Error("Could not patch team member.", { cause: err });
      }

      // Get updated user if patch is for active user.
      if (userId === user?.id) {
        await userActions?.load();
      }
    }),
    patchTeam: useCallbackSafeRef(async (team) => {
      try {
        await reclaim.team.patchTeam(team);
      } catch (err) {
        throw new Error("Could not remove user from team.", { cause: err });
      }
    }),
    leaveTeam: useCallbackSafeRef(async () => {
      try {
        await reclaim.team.leaveTeam();
      } catch (cause) {
        throw new Error("Could not leave team.", { cause });
      }
    }),
  };
};
